import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic01.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic02.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic03.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic04.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic05.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic06.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic07.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic08.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic09.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic10.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic11.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic12.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic13.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic14.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic15.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic16.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic17.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic18.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic19.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-2/pic20.JPG",
];

export default function CountingSteps() {
  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">CLASS IX - COUNTING STEPS TOWARDS NET ZERO</h1>

        <p>
          As part of the G20 endeavors to keep the checks and balances on
          environmental degradation and restoration, the students of Class IX,
          showcased a Class Presentation on the topic – ‘Counting Steps towards
          Net Zero’. The presentation commenced with a Welcome Song in Garhwal
          dialect signifying the importance of trees. The thought-provoking skit
          depicting the Flash Flood and Cloudbursts caused due to ecological
          imbalance (i.e. rapid melting of ice and snow on mountains) in
          Kedarnath was the highlight of the show. The show was concluded with a
          practical solution of ‘counting your carbon emission steps’ i.e.,
          Carbon Foot printing. The parents were able to imbibe the fact that we
          must apply our theoretical information to practical life
        </p>
        <section className="kartavyapath__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
